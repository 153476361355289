@import './custom/functions';
@import './custom/variables';
@import './custom/react-autosuggest';

$sidebarWidth: 249px;
$hiddenSidebarWidth: 62px;

.navbar-horizontal {
  .navbar-brand-absolute {
    z-index: 10;
    position: absolute;
    margin-top: 20px;

    img {
      height: 140px;
    }
  }

  .navbar-brand {
    img {
      height: 140px;
    }
  }

  .navbar-brand-fake {
    width: 140px;
    opacity: 0;
  }

  &.navbar-dark {
    .color-light {
      color: $navbar-dark-color !important;
    }
  }
}

.navbar-search-custom {
  padding-top: 1rem;
  flex-direction: row;

  .react-autosuggest__container {
    display: inline-block;
    width: calc(100% - 64px);
    min-width: 220px;
  }

  .form-group {
    width: 100%;

    .input-group {
      flex: 1;
    }
  }

  .navbar-search {
    .form-control {
      width: 100%;
    }
  }

  .react-autosuggest__suggestions-list {
    max-height: 700px;
  }
}

.navbar-container {
  width: 100%;
  padding-left: 1rem;
}

.nav-item-search-form {
  display: none;

  .navbar-search {
    .input-group-append {
      display: none;
    }
  }
}

.navbar-main-right {
  flex-direction: column;
}

#navbar-main[lang='he'] {
  .nav-item-search-form {
    .navbar-search {
      .input-group {
        justify-content: flex-end;

        .form-control {
          text-align: right;
        }

        .input-group-prepend {
          display: none;
        }

        .input-group-append {
          display: flex;

          .input-group-text {
            padding-left: 0.75rem;
            padding-right: 1rem;
          }
        }
      }
    }
  }

  .navbar-custom-collapse {
    .navbar-nav {
      li.nav-item {
        text-align: right;
      }
    }
  }
}

.g-navbar-search-show {
  .nav-item-search-form {
    display: block;
    padding: 0.8rem 0;
  }

  .search-open-hide {
    display: none;
  }

  .navbar-container {
    width: 100%;
  }
}

#footer-main {
  .navbar-brand {
    img {
      height: 140px;
    }
  }

  .nav-item {
    margin: 0.95rem 0;

    .nav-link {
      font-size: 0.8rem;
    }
  }

  .h4,
  .text-muted {
    font-size: 0.9rem;
  }

  .copyright {
    font-size: 0.8rem;
  }

  .join-us-form {
    button {
      font-size: 1rem;
    }
  }

  .row {
    .navbar-brand {
      img {
        height: 100px;
      }
    }
  }
}

.breadcrumb-dark {
  .breadcrumb-item {
    & + .breadcrumb-item::before {
      content: '/';
      color: $gray-100;
    }
  }
}

.without-caret {
  &:before {
    display: none !important;
  }
}

.dropzone-custom {
  .dz-message {
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: unset;
    background-color: rgba(255, 255, 255, 0.8);
  }

  .dz-preview-cover,
  .dz-preview-img {
    border-radius: unset;
  }
}

.g-sidenav-pinned {
  .custom-loader {
    &.position-fixed {
      padding-left: $sidebarWidth;
    }
  }
}

.admin-bottom-drawer {
  padding-left: $sidebarWidth;
}

.g-sidenav-hidden {
  .admin-bottom-drawer {
    padding-left: $hiddenSidebarWidth;
  }

  .custom-loader {
    &.position-fixed {
      padding-left: $hiddenSidebarWidth;
    }
  }
}

.zi-100 {
  z-index: 100;
}

.article-preview {
  .dz-message {
    padding: 8rem 1rem;
  }
}

.ql-editor p {
  margin-bottom: 0.8em !important;
}

.notification-container {
  & > div > div {
    z-index: 1051 !important;
  }
}

#footer-main {
  .footer-nav {
    .nav-item {
      .nav-link {
        font-size: 1rem;
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, 'lg')) {
  .navbar-horizontal .navbar-nav .nav-item {
    margin-right: 0;
  }

  .with-sub-menu {
    position: relative;

    .nav-item a {
      white-space: nowrap;
    }
  }

  .nav-sub-menu {
    position: absolute;
    top: 100%;
    z-index: 2;

    display: none;
    justify-content: space-between;
    align-items: center;
  }

  .nav-sub-sub-menu {
    position: absolute;
    top: 80%;

    width: fit-content;
    z-index: 2;
    display: none;
  }

  .nav-item.with-sub-sub-menu {
    &:hover {
      background-color: $primary;

      .nav-sub-sub-menu {
        display: flex !important;
        flex-direction: column;
      }
    }
  }

  .nav-item.with-sub-menu {
    &:hover {
      background-color: $primary;

      .nav-sub-menu {
        display: flex !important;
      }
    }
  }

  #navbar-main[lang='he'] {
    .navbar-container {
      .with-sub-menu.nav-item {
        ul.nav-sub-menu {
          right: 0;

          border-top-left-radius: $navbar-nav-link-border-radius;
          border-top-right-radius: 0rem;
        }
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints, 'lg')) {
  .navbar-container {
    padding: 0;
    margin: 0;
    width: auto;
  }
  .navbar-horizontal {
    .navbar-brand-absolute {
      margin-top: 0;
      position: relative;

      img {
        height: 50px;
      }
    }

    .navbar-brand-fake {
      display: none;
    }

    .navbar-brand {
      img {
        height: 70px;
      }
    }
  }

  .navbar-search-custom {
    padding-top: 0;

    .form-group {
      .input-group {
        input {
          width: 220px;
        }
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints, 'md')) {
  #footer-main {
    .footer-nav {
      .nav-item {
        .nav-link {
          font-size: 1.2rem;
        }
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, 'sm')) {
  .nav-item-search-form {
    display: block;
  }
  .g-navbar-search-show {
    .nav-item-search-form {
      display: none;
    }

    .search-open-hide {
      display: block;
    }

    .navbar-container {
      width: 100%;
    }
  }
}
.rdtPicker {
  .rdtTimeToggle {
    visibility: visible;
    &::before {
      display: none;
    }
  }
}

.isHeStyle {
  direction: rtl;
  text-align: right;
}

.isHeButton {
  float: right;
  margin-right: 0 !important;
  &:nth-of-type(1) {
    margin-left: 0.5rem !important;
  }
}

.footnote {
  vertical-align: super;
  font-size: 12px;
  white-space: nowrap;
  color: blue;
  cursor: pointer;
  &:before {
    content: '[ ';
  }
  &:after {
    content: ' ]';
  }
}

.truncatedText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  position: relative;

  &:active,
  &:hover {
    overflow-x: visible;
    span {
      position: relative;
      background-color: #f6f9fc;
      padding-left: 3px;
      padding-right: 15px;
      margin-left: -4px;
    }
  }
}
